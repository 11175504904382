import { useNotificationContext } from '@login/context/UseNotificationContext';
import { getErrorMessage } from '@login/utils/getErrorMessage';
import useSWRMutation from 'swr/mutation';

export function useSWRTrigger<Params, Response>(
  url: string,
  requestType: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'POST',
) {
  const { showNotification } = useNotificationContext();

  const { trigger } = useSWRMutation(
    url,
    function (url: string, { arg }: { arg: Params }) {
      return fetch(url, {
        method: requestType,
        body: JSON.stringify(arg),
      });
    },
  );

  return async function (
    args: Params,
    options: { displayToast?: boolean } = { displayToast: true },
  ): Promise<Response | undefined> {
    try {
      const response = await trigger({ arg: args } as null & Params);

      const result = await response?.json();

      if (options?.displayToast) {
        if (!result.ok) {
          showNotification({ message: result.message, type: 'error' });
          return result;
        } else {
          showNotification({ message: result.message, type: 'success' });
          return result;
        }
      } else {
        return result;
      }
    } catch (error) {
      showNotification({ message: getErrorMessage(error), type: 'error' });
      return;
    }
  };
}
