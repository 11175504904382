'use client';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Transition } from '@headlessui/react';
import { NotificationTypes } from '@login/context/types';
import { NotificationContext } from '@login/context/UseNotificationContext';
import classNames from 'classnames';
import { useContext, useEffect, useState } from 'react';

export const NotificationWrapper = ({
  allowedNotificationTypes = ['error', 'success', 'warning'],
  isGlobalTrigger = false,
}: {
  allowedNotificationTypes?: NotificationTypes[];
  isGlobalTrigger?: boolean;
}) => {
  const { notification, isNotificationOpen, setIsNotificationOpen } =
    useContext(NotificationContext);
  const [renderNotification, setRenderNotification] = useState(false);

  useEffect(() => {
    if (isNotificationOpen) {
      setRenderNotification(true);
    }
  }, [isNotificationOpen]);

  if (
    !allowedNotificationTypes.includes(
      notification?.type as NotificationTypes,
    ) ||
    isGlobalTrigger !== !!notification?.isGlobalTrigger
  ) {
    return null;
  }

  return renderNotification ? (
    <Transition
      show={isNotificationOpen}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      afterLeave={() => setRenderNotification(false)}
    >
      <div
        id="notification-banner"
        className={classNames(
          'relative z-50 flex justify-between items-center py-2 px-4 text-warning-1 text-sm',
          isNotificationOpen &&
            notification?.type === 'success' &&
            'animate-fadeIn',
          isNotificationOpen &&
            (notification?.type === 'error' ||
              notification?.type === 'warning') &&
            'animate-shake',
          notification?.type === 'error' && 'bg-destructive-7',
          notification?.type === 'success' && 'bg-success-8',
          notification?.type === 'warning' && 'bg-warning-6',
        )}
      >
        <div>
          <FontAwesomeIcon
            icon={
              notification?.type === 'success'
                ? faCheckCircle
                : faExclamationTriangle
            }
            size="sm"
            className="mr-3"
          />
          {notification?.message}
        </div>
        {notification?.type === 'error' && (
          <FontAwesomeIcon
            icon={faXmark}
            className="ml-4 cursor-pointer"
            onClick={() => setIsNotificationOpen(false)}
          />
        )}
      </div>
    </Transition>
  ) : null;
};
