import { useNotificationContext } from '@login/context/UseNotificationContext';
import { useRouter } from 'next/navigation';
import * as NProgress from 'nprogress';

export const useProgressRouter = () => {
  const router = useRouter();
  const { setIsNotificationOpen, notification } = useNotificationContext();

  const { push, replace } = router;

  router.push = (href, options) => {
    NProgress.start();
    if (notification?.type === 'error') {
      setIsNotificationOpen(false);
    }

    push(href, options);
  };

  router.replace = (href, options) => {
    NProgress.start();
    if (notification?.type === 'error') {
      setIsNotificationOpen(false);
    }
    replace(href, options);
  };

  return router;
};
