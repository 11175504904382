import { useNotificationContext } from '@login/context/UseNotificationContext';
import { getErrorMessage } from '@login/utils/getErrorMessage';
import { useGoogleLogin } from '@react-oauth/google';
import Image from 'next/image';
import React from 'react';

interface Props {
  onSuccess: Function;
  onFailure?: Function;
  type: 'register' | 'login';
}

const GoogleLogin: React.FC<Props> = ({ onSuccess, type }) => {
  const { showNotification } = useNotificationContext();
  const onClickHandler = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const userData = await fetch(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse?.access_token}`,
        );
        const user = await userData.json();
        await onSuccess({ idToken: tokenResponse.access_token, user });
      } catch (err) {
        showNotification({
          type: 'error',
          message: getErrorMessage(err as Error),
        });
      }
    },
  });

  return (
    <>
      <div className="flex items-center justify-center">
        <button
          className="mt-6 flex items-center justify-center bg-white border border-solid border-regular-grey-4 rounded-sm py-3 w-60"
          onClick={() => onClickHandler()}
        >
          <Image
            src="/google_logo.svg"
            width={15}
            height={16}
            alt="google-logo"
            className="mr-2"
          />{' '}
          {type === 'register' ? 'Signup with Google' : 'Login with Google'}
        </button>
      </div>
    </>
  );
};

export default GoogleLogin;
