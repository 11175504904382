import { useMutation } from '@login/hooks/useMutation';
import { useSWRTrigger } from '@login/hooks/useSWRTrigger';
import { API_ENDPOINTS } from '@login/lib/apiEndpoints';
import {
  ForgotPasswordResponse,
  ForgotPasswordType,
} from '@login/pages/api/auth/forgot-password';
import { LoginResponse, LoginType } from '@login/pages/api/auth/login';
import {
  LoginWithGoogleResponse,
  LoginWithGoogleType,
} from '@login/pages/api/auth/loginWithGoogle';
import {
  ResetPasswordResponse,
  ResetPasswordType,
} from '@login/pages/api/auth/reset-password';
import { SignupResponse, SignupType } from '@login/pages/api/auth/signup';
import {
  SignupWithGoogleResponse,
  SignupWithGoogleType,
} from '@login/pages/api/auth/signupWithGoogle';

export const useLogin = () => {
  return useSWRTrigger<LoginType, LoginResponse>(API_ENDPOINTS.auth.login);
};

export const useLoginWithGoogle = () => {
  const loginWithGoogle = useSWRTrigger<
    LoginWithGoogleType,
    LoginWithGoogleResponse
  >(API_ENDPOINTS.auth.loginWithGoogle);
  return loginWithGoogle;
};

export const useSignup = () => {
  const signup = useSWRTrigger<SignupType, SignupResponse>(
    API_ENDPOINTS.auth.signup,
  );
  return signup;
};

export const useSignupWithGoogle = () => {
  const signUpWithGoogle = useSWRTrigger<
    SignupWithGoogleType,
    SignupWithGoogleResponse
  >(API_ENDPOINTS.auth.signupWithGoogle);
  return signUpWithGoogle;
};

export const useLogout = () => {
  const logout = useSWRTrigger(API_ENDPOINTS.auth.logout);
  return logout;
};

export const useResetPassword = () => {
  const resetPassword = useMutation<ResetPasswordType, ResetPasswordResponse>({
    url: API_ENDPOINTS.auth.resetPassword,
  });
  return resetPassword;
};

export const useForgotPassword = () => {
  const forgotPassword = useMutation<
    ForgotPasswordType,
    ForgotPasswordResponse
  >({ url: API_ENDPOINTS.auth.forgotPassword });
  return forgotPassword;
};
